
.not-found {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`;
	// max-width: 500;
	margin: 0 auto;
	padding: 16px;
	height: calc(100vh - 180px);

	@media (max-width: 400px) {
		height: calc(100vh - 234px);
	}

	.title {
		padding-bottom: 16px; 
	}
};