
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.document-screen {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;

	.document-form {
		width: 90%;
		padding-top: 48px;
		@media (max-width: 599px) {
			width: 100%;
			padding-top: 24px;
		}

		.upload-file-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.field-box {
				width: 86%;
				min-height: 86px;
				padding: 20px 16px;
				background-color: #F1F1F1;
				color: #acacac;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 8px;

				&.uploaded {
					background-color: #daf0fa;
				}
			}
			label {
				margin: 0px;
				.upload-btn {
					width: 10%;
					margin-right: 8px;
					min-height: 86px;
					height: 100%;
					border-radius: 8px;

					@media (max-width: 599px) {
						margin-right: 0px;
					}
				}
			}

			.upload-icon-container {
				margin: 0px;
				.upload-icon {
					font-size: 48px;
				}
			}
			.upload-file {
				display: none;
			}		
		}
		.doc-error {
			padding-top: 4px;
		}

		.action-btn-container {
			padding: 40px 0px 32px 0px;

			.upload-submit-button {
				padding: 16px 48px;
				@media (max-width: 599px) {
					padding: 10px 30px;
				}
			}
		}

		// input {
		// 	background-color: #F1F1F1;
		// 	border-color: #F1F1F1;
		// }
	}
}