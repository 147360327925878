
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.forgot-screen {
	.forgot-form-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
    align-items: center;
		padding: 64px 8% 128px 8%;

		@media (max-width: 599px) {
			flex-direction: column;
			padding: 32px 16px;
		}
		.forgot-form {
			width: 65%;
			padding-top: 32px;
			@media (max-width: 599px) {
				width: 90%;
			}

			.action-btn-container {
				display: flex;
				justify-content: flex-end;
			}
		}
		.error-alert {
			margin-top: 24px;
			width: 65%;
			@media (max-width: 599px) {
				width: 90%;
			}
		}
		.success-message{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 24px 0px;
			text-align: center;

			.redirection-link {
				padding-top: 24px;
			}
		}
	}
}