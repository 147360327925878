
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.loan-screen {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	background-image: url("../../assets/images/background.jpeg");
  background-size: 100% 70%;
	background-repeat: no-repeat;

	.title {
		padding: 48px 0px;
	}
	

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 599px) {
			padding: 0px;
		}

		.paper-container {
			padding: 16px;
		}
	}
}