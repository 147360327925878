
.learn-root {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
};
.title {
  font-weight: bold;
};
.icon {
  padding: 0;
  margin-left: 8px;
  &:hover {
    background: transparent;
  };
};