.loan-form-paper {
	margin-top: 16px;
	position: relative;

	.progress-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		border-radius: 4px 4px 0px 0px;
	}

	.loan-calculation-form {
		width: 100%;
		display: flex;
		flex-direction: row;
		// align-items: center;
		justify-content: space-between;
	
		@media (max-width: 599px) {
			flex-direction: column;
		}
	
		.form-container {
			width: 70%;
	
			@media (max-width: 599px) {
				width: 100%;
			}
	
			.loan-form {
				width: 100%;
				padding: 32px;
	
				.field-label {
					font-weight: bold;
				}
	
				.amount-field {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					padding-top: 16px;
				}
				.slider-marks {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					margin-top: -8px;
	
					p {
						font-size: smaller;
					}
				}
			}
		}
	
		.details-container {
			display: flex;
			flex-direction: column;
			flex: auto ;
			width: 30%;
			height: auto;
			min-height: 100%;
			align-items: center;
			justify-content: center;
			padding: 16px;
			// min-height: 336px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
	
			@media (max-width: 599px) {
				width: 100%;
			}
	
			.details-label {
				color: rgba($color: #ffffff, $alpha: 0.7);
				font-weight: 500;
				font-size: 15px;
			}
			.details-value {
				color: #ffffff;
				font-weight: bold;
				font-size: 18px;
	
				&.total {
					font-size: 28px;
				}
			}
	
			.add-icon {
				color: rgba($color: #ffffff, $alpha: 0.7);
			}
	
			.divider {
				background-color: rgba($color: #ffffff, $alpha: 0.5);
				width: 100%;
				margin: 16px 0px;
			}
	
		}		
	}
}


.footer-action {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 24px;
}