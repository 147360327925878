
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.applications-details {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */
	min-height: calc(100vh - 184px);
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 16px;
	height: 100%;
	background-color: #f5f5f5;

	.pageTitle {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 16px 0px 32px 0px;

		@media (max-width: 599px) {
			padding: 24px 16px;
			flex-direction: column;
			text-align: center;
		}
		.title-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			h5 {
				margin-left: 8px;
			}
		}

		.status-chip {
			color: #ffffff;
			font-weight: bold;
			text-align: center;
		}
	}


	.content {
		width: 100%;
		min-height: 100%;
		margin-top: 32px;
		// background-color: red;
	
		.paper {
			width: 100%;
			margin-bottom: 16px;
			position: relative;
			padding: 16px;

			.progress-bar {
				position: absolute;
				top: 0;
				width: 100%;
				border-radius: 4px 4px 0px 0px;
			}

			.content-grid {
				width: 100%;

				.title {
					color: gray;
				}
	
				.file-link {
					display: flex;
					flex-direction: row;
					align-items: center;
	
					.file-open-icon {
						margin-left: 8px;
					}
				}
			}
		}
	}

	.empty-section {
		text-align: center;
		padding-top: 16px;

		.empty-text {
			padding-bottom: 16px;
		}
	}
}