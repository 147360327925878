
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.home-screen {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */

	.home-banner {
		.banner-paper {
			height: 800px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			background-image: url('../../assets/images/banner-2.png');
			background-size: cover;

			.banner-paper-content {
				align-self: center;
				padding-left: 10%;

				.heading {
					font-weight: 400;
					color: #eaeaea;
					font-size: 50px;
				}
				.subtitle {
					color: #eaeaea;
					font-size: 24px;
				}

				.accounts-button {
					margin-top: 32px;
				}
			}
		}
	}

	.home-family {
		background-image: url("../../assets/images/images-HomeFamily.png");
		background-repeat: no-repeat;
		// background-repeat: repeat-y;
		height: 655px;
		// background-size: cover;
    background-size: 100% 100%;

		.accounts-title {
			padding-left: 10%;
			.accounts-body {
				padding: 8px 0px 16px 0px;
				font-size: 16px;
			}
		}
		

		.heading {
			font-weight: 500;
		}

		.accounts-button {
			padding: 16px 48px;
			@media (max-width: 599px) {
				padding: 10px 30px;
			}
		}
	}

	.section-title {
		color: #12235F;
	}

	.home-family h1 {
		font-size: 60px;
	}

	.home-family p {
		font-size: 30px;
	}

	.keeping-family {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding: 48px 32px;

		@media (max-width: 599px) {
			padding: 32px 24px;
		}

		h3 {
			padding-bottom: 8px;
		}
		h6 {
			line-height: 1.3;
		}
	}

	.relief-goverment {
		display: flex;
		flex-direction: row;
		padding-bottom: 88px;
		justify-content: center;
		align-items: center;
		align-self: center;
		margin: 0 !important;

		@media (max-width: 599px) {
			flex-direction: column-reverse;
			padding-bottom: 32px;

			.relief-goverment-block {
				max-width: 90%;
				margin: 0px;
				align-self: center !important;
				padding-bottom: 32px;
				text-align: center;
			}
		}

		.relief-goverment-subtitle {
			padding: 8px 0px 16px 0px;
			line-height: 1.5;
			font-size: 17px;
		}
		.relief-button {
			padding: 16px 48px;
			@media (max-width: 599px) {
				padding: 10px 30px;
			}	
		}
	}

	.secondary-text {
		color: #353434;
		font-size: 19px;
	}

	.keeping-family-homes {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 48px 32px;

		@media (max-width: 599px) {
			padding: 32px 24px;
		}

		h3 {
			padding-bottom: 8px;
		}
		h6 {
			line-height: 1.3;
		}

		.keeping-family-content {
			
			max-width: 85%;
			@media (max-width: 599px) {
				max-width: 100%;
			}
			.accounts-button {
				padding: 16px 48px;
				@media (max-width: 599px) {
					padding: 10px 30px;
				}
			}

			.family-image {
				width: 90%;
				height: auto;

				@media (max-width: 1024px) {
					width: 100%;
				}
			}

			.keeping-family-text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				padding-left: 80px;

				@media (max-width: 599px) {
					padding-left: 32px;
				}

				

				.keeping-family-text {
					padding: 16px 0px;
					font-size: 15px;
				}

				.keeping-family-subtitle {
					font-weight: 500;
					padding-bottom: 24px;
					font-size: 15px;
				}

			}
		}
	}

	.rent-relief-funds {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 48px 32px;

		.section-title {
			padding-bottom: 32px;
		}

		@media (max-width: 599px) {
			padding: 32px 24px;
		}

		h3 {
			padding-bottom: 8px;
		}
		h6 {
			line-height: 1.3;
		}

		.relief-funds-content {
			max-width: 85%;
			@media (max-width: 599px) {
				max-width: 100%;
				.icon-box-content {
					padding: unset;
					margin: 16px 0px;
				}
			}

			@media (max-width: 599px) {
				.relief-funds-text {
					padding-left: 64px;
				}
			}

			.family-image {
				width: 90%;
				height: auto;
				// border-radius: 64px 0px;
				@media (max-width: 1024px) {
					width: 100%;
				}
			}

		}

		.rent-image-container {
			text-align: center;
		}
	}

	.rent-assitance-homes {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 48px 32px;

		@media (max-width: 599px) {
			padding: 32px 24px;
		}

		h3 {
			padding-bottom: 8px;
		}
		h6 {
			line-height: 1.3;
		}

		.rent-assitance-content {
			
			max-width: 85%;
			padding-top: 32px;
			@media (max-width: 599px) {
				max-width: 100%;
				padding-top: 16px;
			}
			.accounts-button {
				padding: 16px 48px;
				@media (max-width: 599px) {
					padding: 10px 30px;
				}
			}

			.family-image {
				width: 90%;
				height: auto;
				padding-bottom: 32px;
				
				@media (max-width: 1024px) {
					width: 100%;
				}
			}

			.keeping-family-text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				padding-left: 80px;

				@media (max-width: 599px) {
					padding-left: 32px;
				}

				

				.keeping-family-text {
					padding: 16px 0px;
					font-size: 15px;
				}

				.keeping-family-subtitle {
					font-weight: 500;
					padding-bottom: 24px;
					font-size: 15px;
				}

			}
		}
	}


	.keeping-homes {
		padding-bottom: 64px;
		.keeping-homes-title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 40px 8px;
		}
		@media (max-width: 599px) {
			padding-bottom: 32px;
		}	
	}
	.icon-image-blue {
		margin: 40px auto 8px auto;
		padding-top: 8px;
		width: 65px;
		height: 65px;
		background-color: #0e9ad8;
		border-radius: 10px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	}

	.card-works {
		align-items: center;
		height: 100%;
		min-height: 250px;
		overflow: hidden;
		border-radius: 8px;
		text-align: center;

		.card-body {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			h5 {
				width: 80%;
			}
		}
	}

	.icon-box-content {
		font-size: 40px;
		font-weight: 800;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		@media (max-width: 600px) {
			padding-left: 12%;
		}

		.icon-image-paper {
			min-width: 72px;
			width: 84px;
			height: 84px;
			background-color: #D6EDFE;
			border-radius: 16px 0px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 48px;
				height: 48px;
				
			}

			@media (min-width: 1560px) {
				width: 115px;
				height: 115px;

				img {
					width: 65px;
					height: 65px;
				}
			}

			@media (max-width: 600px) {
				width: 72px;
				height: 72px;

				img {
					width: 40px;
					height: 40px;
				}
			}
			
		}

		.icon-box-text {
			padding: 16px 0px 16px 0px;
		}

		.assitance-text {
			line-height: 1.5;
		}
	}

	.text-box-content {
		font-size: 40px;
		font-weight: 800;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		// background-image: url('../../assets/images/frame.svg');
		// background-size: cover;
		border: 1px solid;
		height: 100%;
		padding: 16px;
		position: relative;


		@media (max-width: 600px) {
			padding: 8px;
		}

		.text-paper {
			background-color: #f1f1f1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 24px 16px 16px 16px;
			height: 100%;

			@media (max-width: 600px) {
				padding: 16px 10px 10px 10px;
			}

			img {
				width: 40px;
				height: 40px;
			}
		}

		.icon-box-text {
			padding: 16px 0px 16px 0px;
		}
	}

	.icon-box-content:hover .icon-image-paper {
		background-color: #12235F;
	}


	.payment-image {
		width: 100%;
		height: auto;
	}

	.relief-assistance {
		display: flex;
		padding: 0px 16px;
		flex-direction: row;
		padding-bottom: 16px;
		justify-content: center;
		align-items: center;
		align-self: center;

		.relief-assistance-content {
			max-width: 85%;
			@media (max-width: 599px) {
				max-width: 100%;
				.icon-box-content {
					padding: unset;
					margin: 16px 0px;
				}
			}
			.accounts-button {
				padding: 16px 48px;
				@media (max-width: 599px) {
					padding: 10px 30px;
				}
			}
		}

	}

	.color-lightblue {
		background-color: #f3fbfe;
	}

	.btn {
		padding: .9rem 2rem !important;
	}

	.form-control {
		background-color: rgb(241, 241, 241) !important;
		padding: .9rem 2rem !important;
		border: 0px !important;
	}

	.my-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}
	.how-it-works {

		&.row {
			margin: 0;
		}
		padding-bottom: 32px;

		@media (max-width: 599px) {
			padding-bottom: 0px;
		}

		.how-it-works-title {
			display: flex;
			flex-direction: column;
			width: 100%;
			justify-content: center;
			align-items: center;
			padding: 3rem 0rem;
			@media (max-width: 599px) {
				padding: 1.5rem 0rem;
			}
		}

		.how-it-works-content {
			text-align: center;			
			background-image: url("../../assets/images/how-it-works.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 100%;
			img {
				width: 80%;
				visibility: hidden;
				@media (max-width: 599px) {
					width: 100%;
				}
			}
		}
	}
	.arrow-box-right {
		height: 50px;
		background-image: url("../../assets/images/images-next.png");
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: center;
		overflow: hidden;
		// margin-left: -2px;
		// margin-right: -2px;
	}

	.arrow-box-left {
		height: 50px;
		background-image: url("../../assets/images/images-prev.png");
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: center;
		overflow: hidden;
		// margin-left: -2px;
		// margin-right: -2px;
	}

	.arrow-box-down {
		height: 60px;
		width: 50px;
		background-image: url("../../assets/images/images-down.png");
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center;
		overflow: hidden;
		// margin-top: -2px;
		// margin-bottom: -2px;
	}

	.arrow-box-up {
		height: 60px;
		width: 50px;
		background-image: url("../../assets/images/images-up.png");
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center;
		overflow: hidden;
		// margin-top: -2px;
		// margin-bottom: -2px;
	}

	.sign-up-link {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		background-color: #12235F;
		// background-image: url("../../assets/images/images-Background.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		color: #ffffff;
		padding: 64px 16px;

		.sign-up-text {
			padding-bottom: 32px;
		}

		@media (max-width: 599px) {}

	}


	.error-message {
		color: orangered;
		font-size: 11px;
		margin-left: 10px;
		margin-bottom: 2px;
	}

	@media (min-width: 1320px) and (max-width: 1560px) {
		.home-family {
			height: 620px !important;
		}
		.banner-paper {
			height: 650px !important;
		}

		.heading {
			font-size: 45px !important;
		}
		.subtitle {
			font-size: 20px !important;
		}

		.home-family h1 {
			font-size: 50px;
		}

		.home-family p {
			font-size: 25px;
		}
	}

	@media (min-width: 1024px) and (max-width: 1319px) {
		.home-family {
			height: 520px !important;
		}

		.banner-paper {
			height: 580px !important;
		}

		.heading {
			font-size: 40px !important;
		}
		.subtitle {
			font-size: 20px !important;
		}

		.home-family h1 {
			font-size: 40px;
		}

		.home-family p {
			font-size: 20px;
		}

		.keeping-family h3, .keeping-homes-title h3, .relief-assistance h3, .how-it-works-title h3 {
			font-size: 2.7rem;
		}

	}


	@media (min-width: 768px) and (max-width: 1024px) {
		.home-family {
			height: 400px !important;
		}

		.banner-paper {
			height: 450px !important;
		}

		.heading {
			font-size: 25px !important;
		}
		.subtitle {
			font-size: 15px !important;
		}

		.home-family h1 {
			font-size: 25px;
		}

		.home-family p {
			font-size: 15px;
		}

		.keeping-family h3, .keeping-homes-title h3, .relief-assistance h3, .how-it-works-title h3 {
			font-size: 2.4rem;
		}

		.keeping-family p {
			font-size: 20px;
		}

		.secondary-text {
			font-size: 17px;
		}

		.relief-goverment h1 {
			font-size: 24px;
		}

		.relief-goverment p {
			font-size: 18px;
		}

		.sub-text-box {
			font-size: 25px;
		}

		.secondary-text-box {
			font-size: 18px;
		}
	}

	@media (min-width: 500px) and (max-width: 768px) {
		.home-family {
			height: 300px !important;
		}

		.banner-paper {
			height: 350px !important;
		}

		.heading {
			font-size: 25px !important;
		}
		.subtitle {
			font-size: 15px !important;
		}

		.home-family h1 {
			font-size: 25px;
		}

		.home-family p {
			font-size: 15px;
		}
	}
	@media (min-width: 0px) and (max-width: 499px) {
		.my-6 {
			margin-top: 2rem !important;
			margin-bottom: 2rem !important;
		}
		.home-family {
			height: 200px !important;
		}

		.banner-paper {
			height: 300px !important;
		}

		.heading {
			font-size: 20px !important;
		}
		.subtitle {
			font-size: 12px !important;
		}

		.home-family h1 {
			font-size: 17px;
		}

		.home-family p {
			font-size: 10px;
		}

		.keeping-family h1 {
			font-size: 20px;
		}

		.keeping-family p {
			font-size: 15px;
		}

		.relief-goverment h1 {
			font-size: 16px;
		}

		.relief-goverment p {
			font-size: 11px;
		}

		.sub-text-box {
			font-size: 15px;
		}

		.sub-text {
			font-size: 9px;
		}

		.secondary-text-box {
			display: block;
			font-size: 12px;
		}

		.card-works h4 {
			font-size: 14px;
		}

		.payments h1 {
			font-size: 16px;
		}
	}

	@media (min-width: 0px) and (max-width: 767px) {
		.my-6 {
			margin-top: 2rem !important;
			margin-bottom: 2rem !important;
		}
		/* .home-family {
			height: 200px !important;
		}

		.home-family h1 {
			font-size: 17px;
		}

		.home-family p {
			font-size: 10px;
		} */

		.keeping-family h3, .keeping-homes-title h3, .relief-assistance h3, .how-it-works-title h3 {
			font-size: 1.6rem;
		}

		.keeping-family p {
			font-size: 15px;
		}

		.relief-goverment h1 {
			font-size: 16px;
		}

		.relief-goverment p {
			font-size: 11px;
		}

		.sub-text-box {
			font-size: 15px;
		}

		.sub-text {
			font-size: 9px;
		}

		.secondary-text-box {
			display: block;
			font-size: 12px;
		}

		.secondary-text {
			font-size: 13px !important;
		}

		.card-works h4 {
			font-size: 14px;
		}

		.payments h1 {
			font-size: 16px;
		}
	}
}