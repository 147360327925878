.upload-dialog-fullwidth {
	width: 100%;
	min-width: 300px !important;

	.upload-dialog-content {
		display: flex;
		flex-direction: column;
		padding: 24px;
		justify-content: space-around;
		align-items: center;
		text-align: center;
		min-width: 350px;
		padding-top: 32px;

		.percentage {
			padding-top: 16px;
			text-align: center;
		}
	}
}
