
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
};

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
};

body,html,p,li,a,button,input,span,li,b,table,th,tr,td,select,h1,h2,h3,h4,h5,h6{
	font-family: 'Inter', sans-serif!important;
}


body {
	background-color: #f4f6f8;
	height: 100%;
};

/* a {
	text-decoration: none;
} */

#root {
	height: 100%;
};


.App {
  text-align: center;
};

button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}  

.site-content {
	height: 100%;
}

.sigin-form {
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #e0e1e0;
    border-radius: 6px;
    max-width: 600px;
}

#header-component .logoImage,.footer-component .footer-container .footer-logo {
    max-width: 190px!important;
}



.signin-screen .auth-form .form-header-btns .auth-button {
    padding: 10px 40px!important;
}

/* #header-component .listItem .rent-btn {
    width: 120px!important;
    padding: 8px 15px!important;
    display: inline-block;
} */

.home-screen .home-family .accounts-button,.home-screen .relief-goverment .relief-button,.home-screen .sign-up-link .sign-up-link-content .create-account-button,.home-screen .relief-assistance .relief-assistance-content .accounts-button {
    padding: 10px 24px!important;
    box-shadow: none!important;
}

/* .MuiInputBase-root {
    background: transparent!important;
    border: 1px solid #d0d0d0;
    box-shadow: none!important;
    outline: none!important;
    border-radius: 6px!important;
} */


.MuiInputBase-root::before{
	display:none;
}

.css-vrq3gn-MuiGrid-root>.MuiGrid-item {
    padding-top: 20px!important;
}

.css-vrq3gn-MuiGrid-root{
    margin-top: -24px!important;
}

/* .home-screen .color-lightblue .icon-box-content {
    background: #e1f2f8!important;
    box-sizing: border-box!important;
    padding: 20px!important;
    border-radius: 4px!important;
    border: 1px dashed #bdd5de!important;
}
 */

@media only screen and (min-width: 1024px){
    /* .css-z1ncts-MuiTypography-root,.home-screen .keeping-family h3, .home-screen .keeping-homes-title h3, .home-screen .relief-assistance h3, .home-screen .how-it-works-title h3 {
        font-size: 2rem!important;
    } */

    /* .css-row21j-MuiTypography-root {
        font-size: 1.25rem!important;
        font-weight: 500!important;
    } */
}

@media only screen and (max-width:620px){
    .signin-screen .auth-form .sigin-form{
        width: 100%!important;
    }
    .sigin-form{
        padding: 15px!important;
    }

    .css-z1ncts-MuiTypography-root{
        font-size: 1.25rem!important;
    }
    .home-screen .keeping-family h3, .home-screen .keeping-homes-title h3, .home-screen .relief-assistance h3, .home-screen .how-it-works-title h3 {
        font-size: 1.25rem!important;
    }
}