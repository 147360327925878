
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.profile-root {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */
	min-height: calc(100vh - 184px);
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 16px;

	.pageTitle {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 32px;

		@media (max-width: 599px) {
			padding: 0px 16px;
		}
	}

	.form-conatiner {
		display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

		.sigin-form {
			width: 65%;
			padding-top: 24px;
			@media (max-width: 599px) {
				width: 90%;
			}

			.action-btn-container {
				display: flex;
				justify-content: flex-end;
			}

			// input {
			// 	background-color: #F1F1F1;
			// 	border-color: #F1F1F1;
			// }
		}
	}
}