.enhanced-table-toolbar-root {
	padding: 0px 16px;

	// &.highlight {
	// 	color: theme.palette.text.primary,
	// 	background-color: theme.palette.secondary.dark,
	// }

	&.searchBar {
		justify-content: center;
	}

	.title {
		flex: 1 1 100%;

	}
	
}