.drawer {
  width: 100%;
  max-width: 400px;

  .root {
    height: 100%;
    padding: 8px;

    .nav {
      margin-bottom: 8px;
    };

    .listItem {
      flex-direction: column;
      align-items: flex-start;
    };
    .listItemIcon {
      min-width: auto;
    };
    .listItemLink {
      text-decoration: none;
    };
    .closeIcon {
      justify-content: flex-end;
      cursor: pointer;
    };
    .divider {
      width: 100%;
    };
  };
};