.footer-component {
	padding: 3px, 0px;
	background-color: #12235F;
	width: 100%;

	.footer-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 16px 0px;

		.footer-links {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}



		.rights {
			color: #FFFFFF;
			font-weight: 400;
			font-size: 18px;
			margin: 1rem;
			// font-weight: bold;
		}

		@media (max-width: 599px) {
			flex-direction: column;			
			.rights {
				margin: 0;
			}
		}
	
		.footer-logo {
			max-width: 250px;
			height: auto;

			@media (max-width: 400px) {
				padding-top: 8px;
				max-width: 200px;
			}
		}

		@media (max-width: 768px) {
			.rights {
				font-size: 16px;
			}
		}
	}
};