
#header-component {
	// max-width: theme.layout.contentWidth,
	width: 100%;
	margin: 0 auto;
	padding: 0px 64px;
	max-height: 100px;

	@media (max-width: 599px) {
		padding: 0px 16px;
	}

	.flexGrow {
		flex-grow: 1
	};
	.navigationContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	};
	.listItem {
		cursor: pointer;
		padding-top: 0;
		padding-bottom: 0;

		.rent-btn {
			width: 148px;
			padding: 12px 24px;
			.rent-btn-text {
				text-transform: none;
			}
		}
	};
	.listItemText {
		flex: 0px 0px auto;
		white-space: nowrap;
		text-decoration: none;
	};
	.listItemButton {
		white-space: nowrap;
	};
	.iconButton {
		padding: 0px;
		&:hover {
			background: 'transparent';
		};
	};
	.logoContainer {
		width: 100%;
		height: 100px;
		@media (max-width: 599px) {
			height: 80px;
		}
	};
	.logoImage {
		// max-width: 250px;
		padding-top: 24px;
		width: unset;
		height: unset;
	};
	.popperStyle {
		z-index: 1;
		padding: 8px 0px !important;
	};
	.avatar {
		cursor: 'pointer';
	}
};