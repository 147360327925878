
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.signin-screen {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */
	min-height: calc(100vh - 194px);
		
	.sign-accounts-key {
		background-image: url("../../assets/images/images-HomeKeys.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 857px !important;

		.accounts-title {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.sign-accounts-key h1{
		font-size: 60px;
	}

	.sign-accounts-key p {
		font-size: 30px
	}

	.create-account {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.create-account-content {
		display: flex;
		flex-direction: row;
		padding: 64px 8% 32px 8%;

		@media (max-width: 599px) {
			flex-direction: column;
			padding: 16px 16px;
		}
		.create-account-image {
			flex: 1;
			width: 100%;
			min-width: 50%;
			padding: 0px 32px;
			@media (max-width: 599px) {
				padding: 0px;
			}

			.payment-image {
				width: 85%;
				height: auto;
				@media (max-width: 599px) {
					width: 100%;
				}
			}
		}
		.create-account-text {
			flex: 1;
			width: 100%;
			min-width: 50%;
			padding: 0px 32px;
			p {
				padding-top: 8px;
			}
			@media (max-width: 599px) {
				padding: 0px;
				padding-top: 32px;
			}
		}
	}
	.auth-form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
    align-items: center;
		padding: 64px 8% 32px 8%;

		@media (max-width: 599px) {
			flex-direction: column;
			padding: 32px 16px;
		}
		.form-header-btns {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 24px 0px;

			@media (max-width: 599px) {
				padding: 16px 0px;
			}
			
			.auth-button {
				padding: 16px 48px;

				&.auth-button-left {
					margin-right: 8px;
				}
				&.auth-button-right {
					margin-left: 8px;
				}
				&.auth-inactive {
					background-color: #021B27;
				}
				@media (max-width: 599px) {
					padding: 12px 32px;
				}
			}
		}

		.sigin-form {
			width: 65%;
			padding-top: 24px;
			@media (max-width: 599px) {
				width: 90%;
			}

			.action-btn-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}


			// input {
			// 	background-color: #F1F1F1;
			// 	border-color: #F1F1F1;
			// }
		}
		.error-alert {
			margin-bottom: 16px;
			width: 65%;
			@media (max-width: 599px) {
				width: 90%;
			}
		}
	}

	.secondary-text {
		font-size: 22px;
	}

	.sub-text-box {
		width: 80%;
	}

	.secondary-text-box {
		position: relative;
		left: 80px;
		width: 80%;
		min-height: 65px;
		font-weight: 600;
		font-size: 22px;
	}


	.h-170 {
		height: 70px !important;
	}

	.color-lightblue {
		background-color: #f3fbfe;
	}

	.btn {
		padding: .9rem 2rem !important;
	}

	.form-control {
		background-color: rgb(241, 241, 241) !important;
		padding: .9rem 2rem !important;
		border: 0px !important;
	}

	.my-6 {
		margin-top: 5rem !important;
		margin-bottom: 5rem !important;
	}

	.error-message {
		color: orangered;
		font-size: 11px;
		margin-left: 10px;
		margin-bottom: 2px;
	}

	@media (min-width: 1320px) and (max-width: 1560px) {
		.sign-accounts-key {
				height: 620px !important;
		}

		.sign-accounts-key h1 {
				font-size: 50px;
		}

		.sign-accounts-key p {
				font-size: 25px
		}
	}

	@media (min-width: 1024px) and (max-width: 1319px) {
		.sign-accounts-key {
				height: 520px !important;
		}

		.sign-accounts-key h1 {
				font-size: 40px;
		}

		.sign-accounts-key p {
				font-size: 20px
		}
	}


	@media (min-width: 768px) and (max-width: 1024px) {
		.sign-accounts-key {
				height: 400px !important;
		}

		.sign-accounts-key h1 {
				font-size: 30px;
		}

		.sign-accounts-key p {
				font-size: 15px
		}
	}

	@media (min-width: 0px) and (max-width: 767px) {
		.my-6 {
				margin-top: 2rem !important;
				margin-bottom: 2rem !important;
		}

		.sign-accounts-key {
				height: 220px !important;
		}

		.sign-accounts-key h1 {
				font-size: 20px;
		}

		.sign-accounts-key p {
				font-size: 12px;
		}
		.sub-text-box {
				font-size: 15px;
		}

		.sub-text {
				font-size: 9px;
		}

		.secondary-text-box {
				display: block;
				font-size: 12px;
		}

		.secondary-text {
				font-size: 13px !important;
		}

		.payments h1 {
				font-size: 16px;
		}
	}
}