
#not-found {
  .extraSmall {
    font-size: 10;
  };
  .small {
    font-size: 20;
  };
  .medium {
    font-size: 30;
  };
  .large {
    font-size: 40;
  };
};