
.privacy-screen {
	padding-bottom: 40px;
	@media (max-width: 599px) {
		padding-top: 40px;
	}

	.section-title {
		color: #12235F;
	}

	.list-padding{ 
		padding-left: 16px;
	}
}