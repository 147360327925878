
button:hover, button:focus {
	outline: none !important;
}
.text-bold {
	font-weight: bold !important;
}

.applications-root {
	/* .text-bold {
		font-family: 'Poppins' !important;
		font-weight: bolder;
	} */
	min-height: calc(100vh - 184px);
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 16px;
	height: 100%;
	background-color: #f5f5f5;

	.pageTitle {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 32px;

		@media (max-width: 599px) {
			padding: 24px 16px;
		}
	}


	.content {
		width: 100%;
		min-height: 100%;
		// background-color: red;
	
		.paper {
			width: 100%;
			margin-bottom: 16px;
			position: relative;

			.progress-bar {
				position: absolute;
				top: 0;
				width: 100%;
				border-radius: 4px 4px 0px 0px;
			}

			.pagination-toolbar {
				align-items: baseline;
			}

			.table {
				min-width: 400px;

				.no-records {
					padding: 16px;
				}
				td, th {
					padding: 16px;
					vertical-align: inherit;
				}

				.name-cell {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
				}

				.row-hover {
					cursor: pointer;

					.status-chip {
						color: #ffffff;
						font-weight: bold;
						text-align: center;
					}
				};
			}
		}
	}

	.empty-section {
		text-align: center;
		padding-top: 16px;

		.empty-text {
			padding-bottom: 16px;
		}
	}
}