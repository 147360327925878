.change-pass-dialog {
	width: 100%;

	.dialog-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px;

		.dialog-title {
			text-transform: capitalize;
			padding-left: 16px;
		}
  }

	.dialog-content {
		padding: 32px 24px 0px 24px;
		/* @media (max-width: 599px) {
			padding: 32px 16px 0px 16px;
		} */

		.input-field {
			margin-bottom: 16px;
		}
	}

	.dialog-actions {
		padding: 0px 16px 16px 0px;
	}
}