.loan-documents-form {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	align-items: center;

	@media (max-width: 599px) {
		flex-direction: column;
	}

	.document-form {
		width: 100%;
		padding: 32px 16px 32px 32px;

		@media (max-width: 599px) {
			width: 100%;
			padding: 16px 8px 16px 16px;
		}

		.upload-file-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.field-box {
				width: 86%;
				min-height: 86px;
				padding: 20px 16px;
				background-color: #F1F1F1;
				color: #acacac;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 8px;

				&.uploaded {
					background-color: #daf0fa;
				}
			}
			label {
				margin: 0px;
				.upload-btn {
					width: 10%;
					margin-right: 8px;
					min-height: 86px;
					height: 100%;
					border-radius: 8px;

					@media (max-width: 599px) {
						margin-right: 0px;
					}
				}
			}

			.upload-icon-container {
				margin: 0px;
				.upload-icon {
					font-size: 48px;
				}
			}
			.upload-file {
				display: none;
			}		
		}
		.doc-error {
			padding-top: 4px;
		}
	}
	
}